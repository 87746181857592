
  const loadId = 'sw_9B6419A7-EE56-4194-88B2-55C27859644D';
  import {Workbox} from 'https://storage.googleapis.com/workbox-cdn/releases/6.4.1/workbox-window.prod.mjs';

  const loadValue = localStorage.getItem(loadId);
  if (!loadValue) {
    function updateServiceWorker(reg) {
      reg.update().catch(e => {
        if (e.message?.includes('An unknown error occurred when fetching the script.')) {
          //Getting here, means that the script could not be downloaded (User's network is turned off).
          return;
        }

        //Getting here means something else happened, and we need it to pass through the normal error handler.
        throw e;
      });

      //polling for an update every 30min
      setTimeout(() => updateServiceWorker(reg), 30 * 60000);
    }

    if ('serviceWorker' in navigator) {
      const wb = new Workbox('/softtech-serviceworker.js');

      const showSkipWaitingPrompt = async event => {
        // Assuming the user accepted the update, set up a listener
        // that will reload the page as soon as the previously waiting
        // service worker has taken control.
        wb.addEventListener('controlling', () => {
          // At this point, reloading will ensure that the current
          // tab is loaded under the control of the new service worker.
          // Depending on your web app, you may want to auto-save or
          // persist transient state before triggering the reload.
          window.location.reload();
        });

        // When `event.wasWaitingBeforeRegister` is true, a previously
        // updated service worker is still waiting.
        // You may want to customize the UI prompt accordingly.

        const updateAccepted = true; //await promptForUpdate();

        if (updateAccepted) {
          wb.messageSkipWaiting();
        }
      };

      let refreshing = false;
      wb.addEventListener('controllerchange', event => {
        if (refreshing) return;

        refreshing = true;
        window.location.reload();
      });

      // Add an event listener to detect when the registered
      // service worker has installed but is waiting to activate.
      wb.addEventListener('waiting', event => {
        showSkipWaitingPrompt(event);
      });

      wb.addEventListener('installing', event => {
        event.addEventListener('statechange', event => {
          showSkipWaitingPrompt(event);
        });
      });

      wb.addEventListener('updatefound', event => {
        showSkipWaitingPrompt(event);
      });

      wb.addEventListener('fetch', event => {
        event.respondWith(
          (async () => {
            if (
              event.request.mode === 'navigate' &&
              event.request.method === 'GET' &&
              registration.waiting &&
              (await wb.clients.matchAll()).length < 2
            ) {
              wb.messageSkipWaiting();
              //We’ll use the HTTP Refresh header to refresh the page after 0 seconds
              return new Response('', {
                headers: {Refresh: '0'}
              });
            }

            return (await caches.match(event.request)) || fetch(event.request);
          })()
        );
      });
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });
      wb.register(true).then(reg => {
        updateServiceWorker(reg);
      });
    }
  }
